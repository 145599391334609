import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HomepageMasthead from "../components/homepage-masthead";
import BannerText from "../components/banner-text";
import ServicesGrid from "../components/services-grid";
import MasonryGallery from "../components/masonry-gallery";

const IndexPage = ({data}) => {

    let galleryImages = Object.assign({}, data.galleryImages.frontmatter);

    for (let key in galleryImages) {
        if (galleryImages.hasOwnProperty(key)) {
            galleryImages[key] = galleryImages[key].childImageSharp.fluid;
        }
    }

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile
    };

    return (
        <>
            <Layout>
                <SEO title="Welcome to R. Fulcher & Sons"/>
                <HomepageMasthead/>
                <BannerText title="Serving customers in"
                            summary="Leigh-on-Sea, Hockley, Southend, Basildon, Benfleet, Rayleigh, Rochford, Thorpe Bay, Shoeburyness and Canvey Island"
                            background={data.backgroundPins.childImageSharp.fluid}
                            arrowPosition="bottom"/>
                <ServicesGrid/>
                <BannerText title="Request a quote"
                            summary={`Call our office <a href="tel:${contact.tel.replace(/\s+/g, '')}">${contact.tel}</a>, mobile <a href="tel:${contact.mob.replace(/\s+/g, '')}">${contact.mob}</a> or email <a href="mailto:${contact.email}?subject=Enquiry">${contact.email}</a>`}
                            background={data.backgroundPhones.childImageSharp.fluid}
                            arrowPosition="top"/>
                <MasonryGallery images={galleryImages} text="View the gallery" link="/galleries"/>
            </Layout>
        </>
    )
}

export default IndexPage

export const query = graphql`
    query IndexPageQuery {
        site {
            siteMetadata {
                email
                telephone
                mobile
            }
        }
        backgroundPins: file(relativePath: {eq: "background-pins.png"}) {
            childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundPhones: file(relativePath: {eq: "background-phones.png"}) {
            childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        galleryImages: markdownRemark(fileAbsolutePath: {regex: "\/masonry-galleries\/index.md\/"}) {
            frontmatter {
                image_one {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_two {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_three {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_four {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_five {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
