import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Button from "../components/button"

const HomepageMasthead = () => {
    const data = useStaticQuery(graphql`
        query {
            background: file(
                absolutePath: {
                    glob: "**/content/services/new-builds/images/23.jpg"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1400, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sps: file(relativePath: { eq: "sps.svg" }) {
                publicURL
            }
            baumit: file(relativePath: { eq: "baumit.svg" }) {
                publicURL
            }
            baumit_award: file(relativePath: { eq: "baumit-award.svg" }) {
                publicURL
            }
        }
    `)

    return (
        <>
            <BackgroundImage
                tag="div"
                className="relative bg-cover bg-center"
                fluid={data.background.childImageSharp.fluid}
            >
                <div className="relative flex justify-center items-center py-20 z-10 container">
                    <div className="flex flex-col items-center max-w-masthead-content text-center">
                        <h1 className="mb-8 text-white text-3xl leading-tight sm:text-4xl md:text-5xl lg:mb-12 lg:text-6xl xl:text-8xl">
                            Specialists in traditional wall rendering and modern
                            render systems
                        </h1>
                        <Button
                            link="/services"
                            text="Explore our services"
                            size="lg"
                            extraClasses="mb-10 lg:mb-12"
                        />

                        <div className="flex flex-col items-center max-w-sm md:flex-row md:max-w-none">
                            <img
                                src={data.sps.publicURL}
                                alt="SPS Logo"
                                width="458"
                                height="64"
                                className="mb-4 h-16 md:mb-0"
                            />
                            <img
                                src={data.baumit_award.publicURL}
                                alt="Baumit Award"
                                width="91"
                                height="160"
                                className="mb-8 h-40 md:mx-8 md:mb-0"
                            />
                            <img
                                src={data.baumit.publicURL}
                                alt="Baumit Logo"
                                width="80"
                                height="80"
                                className="h-20"
                            />
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 left-0 w-full h-64 bg-gradient-to-t from-white"></div>
            </BackgroundImage>
        </>
    )
}

export default HomepageMasthead
