import React from "react";
import { Link } from "gatsby";

const Button = (props) => {
    let size_classes = props.size && props.size === 'lg' ? 'px-6 py-3 lg:px-10 lg:py-3 lg:text-2xl' : 'px-6 py-3 lg:px-8 lg:text-xl';
    let base_classes = 'bg-white border border-primary text-primary uppercase font-bold transform transition-color duration-200 hover:bg-primary focus:bg-primary hover:text-white focus:text-white';

    let button_classes = props.extraClasses ? size_classes + ' ' + base_classes + ' ' + props.extraClasses : size_classes + ' ' + base_classes;

    return (
        <Link to={props.link}
           className={button_classes}>{props.text}</Link>
    )
};

export default Button;
