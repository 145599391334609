import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Button from "../components/button";

const ServicesGrid = (props) => {

    const data = useStaticQuery(graphql`
        query {
            services: allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/content/services/*/index.md"}}, sort: {fields: frontmatter___order}) {
                edges {
                    node {
                        frontmatter {
                            path
                            title
                            intro
                            featured_image {
                                childImageSharp {
                                    fluid(maxWidth: 800, quality: 90) {
                                      ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const services = data.services.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.intro = obj.node.frontmatter.intro;
        rObj.image = obj.node.frontmatter.featured_image.childImageSharp.fluid;
        return rObj
    });

    let desktop_bg_class;
    let mobile_bg_class;
    let rows = 1;

    return (
        <section className="grid lg:grid-cols-2">
            { services.map((service, index) => {

                if (index % 2 === 0) {
                    rows++;
                    mobile_bg_class = 'bg-white-darker'
                } else {
                    mobile_bg_class = 'bg-white-dark'
                }

                if (rows % 2 === 0) {
                    desktop_bg_class = 'lg:bg-white-dark';
                } else {
                    desktop_bg_class = 'lg:bg-white-darker';
                }

                let bg_classes = mobile_bg_class + ' ' + desktop_bg_class;

                return(
                    <div className={'relative ' + bg_classes} key={index}>
                        <div className="relative ml-0 pt-12 max-w-md z-10 container md:py-24">
                            <h2 className="mb-6 text-primary word-spacing-screen leading-tight text-2xl md:text-3xl lg:text-4xl">{service.title}</h2>
                            <p className="mb-6 md:text-xl">{service.intro}</p>
                            <Button link={service.path} text="Find out more" extraClasses="inline-block"/>
                        </div>

                        <BackgroundImage tag="div"
                                         className="h-56 bg-center md:absolute md:top-0 md:right-0 md:w-2/5 md:h-full services-image"
                                         fluid={service.image}></BackgroundImage>
                    </div>
                )
            })}
        </section>
    )
};

export default ServicesGrid;
