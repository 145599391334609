import React from "react";
import Image from "gatsby-image";
import Button from "../components/button";

const MasonryGallery = (props) => {

    const image_classes = 'absolute top-0 left-0 w-full h-full';

    return props.images.image_one && props.images.image_two && props.images.image_three && props.images.image_four && props.images.image_five ? (
        <section className="grid md:grid-cols-6 md:grid-rows-2">
            <div className="relative w-full h-32 md:col-span-2 md:row-span-2 md:pt-full md:h-auto">
                <Button link={props.link} text={props.text} extraClasses="absolute bottom-0 left-0 mb-8 ml-8 lg:mb-16 lg:ml-16 z-10"/>
                <Image fluid={props.images.image_one}
                       className={image_classes}/>
            </div>
            <div className="relative h-32 md:h-full md:col-span-2 lg:col-span-3">
                <Image fluid={props.images.image_two}
                       className={image_classes}/>
            </div>
            <div className="relative h-32 md:h-full md:col-span-2 lg:col-span-1">
                <Image fluid={props.images.image_three}
                       className={image_classes}/>
            </div>
            <div className="relative h-32 md:h-full md:col-span-2 lg:col-span-1">
                <Image fluid={props.images.image_four}
                       className={image_classes}/>
            </div>
            <div className="relative h-32 md:h-full md:col-span-2 lg:col-span-3">
                <Image fluid={props.images.image_five}
                       className={image_classes}/>
            </div>
        </section>
    ) : null;
};

export default MasonryGallery;
